import React, { useReducer, useRef, useEffect } from 'react'
import logo from 'assets/globals/logo_svg.svg'
import { useNavigate, Link } from 'react-router-dom'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { TextNavAnchor } from 'atoms/links'
import MobileNavModal from './mobileNav'
import logoMobile from 'assets/globals/logo_mobile.svg'
import { Popover, Transition as PopTransition } from '@headlessui/react'

import './header.css'
import CathLabRoutes from 'molecules/cathLabRoutes'
import StentRoutes from 'molecules/stentRoutes'
import BalloonRoutes from 'molecules/balloonRoutes'
import ResourceRoutes from 'molecules/resourceRoutes'
import OtherRoutes from 'molecules/otherRoutes'

gsap.registerPlugin(ScrollTrigger)

export default function Header() {
  const [mobileNav, toggleMobileNav] = useReducer((s) => !s, false)
  const headerWrapperRef = useRef(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const servicesBtn = useRef<HTMLButtonElement>(null)
  const productsBtn = useRef<HTMLButtonElement>(null)
  const techBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [mobileNav])

  useEffect(() => {
    const showAnim = gsap
      .from(headerWrapperRef?.current, {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1)
    ScrollTrigger.create({
      start: 'top top',
      end: 99999,
      onUpdate: (self) => {
        if (self.direction === -1) {
          showAnim.play()
        } else {
          showAnim.reverse()
        }
      },
    })
  }, [])

  const popNavigation = (
    target: string,
    popId: 'tech' | 'products' | 'services',
  ) => {
    switch (popId) {
      case 'tech': {
        navigate('/coming-soon')
        close()
        // navigate(`/tech/${target}`)
        // close()
        return techBtn.current?.click()
      }
      case 'products': {
        navigate('/coming-soon')
        close()
        // navigate(`/products/${target}`)
        // close()
        return productsBtn.current?.click()
      }
      case 'services': {
        navigate('/coming-soon')
        close()
        // navigate(`/resources/${target}`)
        // close()
        return servicesBtn.current?.click()
      }
      default:
    }
  }
  return (
    <>
      <div
        className={`fixed max-w-md w-full top-0 right-0 h-screen bottom-0 tranition-all duration-300 ease-in-out bg-opacity-50 xl:hidden block z-[9999] ${
          mobileNav ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="h-full overflow-y-auto z-[9999]">
          <MobileNavModal toggle={toggleMobileNav} />
        </div>
      </div>

      <header
        ref={headerWrapperRef}
        className="bg-white lg:bg-transparent fixed top-0 left-0 right-0 z-50 pt-0 xl:pt-3 mob-header-shadow"
      >
        <nav
          className="flex realtive flex-nowrap justify-between items-center top-0 left-0 right-0 bottom-0 gap-5 2xl:gap-6
          xl:bg-transparent bg-white py-0 md:py-3 md:pl-5 w-container-full z-50 xl:w-container-lg 2xl:w-container"
        >
          <div className="flex-1 relative rounded-md bg-white flex items-center py-3 2xl:py-5 self-stretch min-h-full header-shadow">
            <div className="flex-0 grid px-1 pl-2 xl:pl-4 place-content-start md:place-content-center basis-44 2xl:basis-48">
              <Link to="/">
                <img
                  src={logo}
                  className="h-10 md:block hidden xl:h-12 w-auto object-contain object-center"
                  alt="Logo of Innvolution"
                />
                <img
                  src={logoMobile}
                  className="h-10 md:hidden block pl-1 xl:h-12 w-auto object-contain object-center"
                  alt="Logo of Innvolution"
                />
              </Link>
            </div>
            <div className="flex-1 items-center justify-end pr-10 lg:pr-4 xl:pr-10 xl:gap-10 gap-5 xl:flex hidden">
              <TextNavAnchor
                size="base"
                className="xl:!text-lg hover:text-orange"
                href="/who-we-are"
                text="Who we are"
              />

              <Popover>
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      onClick={() => {
                        document.addEventListener('scroll', () => close(), {
                          once: true,
                        })
                      }}
                      className="flex items-center gap-1 text-black hover:text-orange text-base lg:text-lg hover:underline font-regular outline-none whitespace-nowrap focus-visible:underline underline-offset-4 decoration-from-font text-opacity-80 transition-colors ease-in-out focus-visible:text-opacity-100 hover:text-opacity-100"
                    >
                      Products
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className={` ${
                          open ? 'rotate-180' : ''
                        } transition-all ease-in-out w-4 h-4`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </Popover.Button>

                    <PopTransition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      className="absolute top-[6rem] xl:top-[5rem] 2xl:top-[6.5rem] lg:left[7vw] xl:left[24.5vw] xxl:left[32.5%] 2xl:left[41.5%] -translate-x-1/2 left-1/2 w-full"
                    >
                      <Popover.Panel className=" max-xl:w-[88.5vw] origin-left p-5 rounded-md flex bg-white">
                        <div className="basis-[24%] grow-0 shrink p-2 flex flex-col px-5 gap-1">
                          <CathLabRoutes onClose={close} />
                        </div>
                        <div className="basis-[24%] grow-0 shrink  flex flex-col  p-2 gap-1">
                          <StentRoutes onClose={close} />
                        </div>
                        <div className="xl:basis-[35%] 2xl:basis-[28%] grow-0 shrink  flex flex-col  p-2 gap-1">
                          <BalloonRoutes onClose={close} />
                        </div>
                        <div className="xl:basis-[24%] grow shrink flex flex-col gap-1 items-start justify-start pt-2">
                          <OtherRoutes onClose={close} />
                        </div>
                      </Popover.Panel>
                    </PopTransition>
                  </>
                )}
              </Popover>

              {/* <TextNavAnchor
                size="base"
                className="xl:!text-lg hover:text-orange"
                href="/services-and-support"
                text="Services & support "
              /> */}
              <TextNavAnchor
                size="base"
                className="xl:!text-lg hover:text-orange"
                href="/clinical-gallery"
                text="Clinical gallery "
              />
              <TextNavAnchor
                size="base"
                className="xl:!text-lg hover:text-orange"
                href="/innovation"
                text="Innovation"
              />

              <Popover>
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      onClick={() => {
                        document.addEventListener('scroll', () => close(), {
                          once: true,
                        })
                      }}
                      className="flex relative hover:text-orange items-center gap-1 text-black text-base lg:text-lg hover:underline font-regular outline-none whitespace-nowrap focus-visible:underline underline-offset-4 decoration-from-font text-opacity-80 transition-colors duration-300 ease-in-out focus-visible:text-opacity-100 hover:text-opacity-100"
                    >
                      Resources
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className={` ${
                          open ? 'rotate-180' : ''
                        } transition-all duration-300 ease-in-out w-4 h-4`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </Popover.Button>

                    <PopTransition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      className="absolute top-[6rem] xl:top-[5rem] 2xl:top-[6.5rem] -translate-x-[49%] xl:-translate-x-[55%] w-60 xl:w-80"
                    >
                      <Popover.Panel className=" p-3 rounded-md flex bg-white">
                        <div className="p-2">
                          <div className="flex flex-col items-start">
                            <ResourceRoutes onClose={close} />
                          </div>
                        </div>
                      </Popover.Panel>
                    </PopTransition>
                  </>
                )}
              </Popover>
            </div>
          </div>
          <div className="flex-0 rounded-md min-h-full self-stretch md:block hidden md:basis-44 2xl:basis-48 header-shadow">
            <Link
              to="/contact-us"
              className="justify-center rounded-md h-full font-regular tracking-wide bg-white stroke-orange hover:stroke-white hover:fill-white fill-orange outline-orange hover:border-white border-transparent border-2 text-black hover:text-white px-3 py-3 lg:py-5 flex items-center gap-3 hover:bg-orange transition-all duration-300 ease-in-out text-lg "
            >
              Contact us
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="inherit"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </Link>
          </div>
          <div className="xl:hidden block pr-1 md:mr-8">
            <button
              onClick={toggleMobileNav}
              className="p-3 bg-white bg-opacity-40 outline-none  header-shadow rounded-full header-shadow stroke-blue"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="inherit"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </button>
          </div>
        </nav>
      </header>
    </>
  )
}
